<template>
  <PageContentLayoutOnly role="region" :aria-label="$t('profile.title')">
    <div class="notification headerColor box is-4" v-if="true">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }" v-if="authUser">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(confirmUpdate)" class="form">
            <fieldset>
              <div class="form-grid">
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <span style="white-space: nowrap">
                    <valid-input
                      label="Full Name"
                      name="FullName"
                      type="text"
                      vid="fullName"
                      placeholder="Full Name"
                      spellcheck="true"
                      maxlength="50"
                      rules="required|max:50"
                      v-model="formData.fullName"
                    />
                  </span>
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    label="Email Address"
                    name="EmailAddress"
                    maxlength="100"
                    type="email"
                    vid="emailAddress"
                    placeholder="Email Address"
                    spellcheck="true"
                    rules="required|max:100"
                    v-model="formData.emailAddress"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    label="Phone"
                    name="Phone"
                    maxlength="20"
                    type="phone"
                    vid="phone"
                    placeholder="Phone"
                    spellcheck="true"
                    rules="max:20"
                    v-model="formData.phone"
                  />
                </div>
                <div v-if="imageContent">
                  <div>
                    <img height="50%" width="50%" :src="imageContent" />
                  </div>
                </div>
                <div>
                  <b-button @click.prevent="saveProfileImage()" type="button" class="is-text">
                    Save Profile Image
                  </b-button>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                Save
              </button>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
    <Modal :toggle.sync="toggle">
      <ProfileImageModal :toggle="toggle" :profileImageDocument="this.profile"> </ProfileImageModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { mapState, mapGetters } from 'vuex'
import ValidInput from '@/components/inputs/ValidInput'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ProfileImageModal from './component/ProfileImageModal'
import Modal from '@/components/Modal'

import { data } from './keys/data'
import { methods } from './keys/methods'

let DEFAULT_FORM_VALUES = {
  fullName: '',
  emailAddress: '',
  phone: ''
}

export default {
  components: {
    PageContentLayoutOnly,
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    ProfileImageModal,
    Modal
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  mounted() {
    this.loading = true

    this.refreshUserProfile()

    this.formData = { ...DEFAULT_FORM_VALUES }
    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    },
    async toggle() {
      if (this.toggle == false) {
        this.toggle = false
        this.reload()
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: { profile: { title: 'Profile' } }
    }
  }
}
</script>

<style lang="scss">
textarea {
  min-width: 600px !important;
}

button {
  padding: 5px;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 940px;
  }

  .app-properties {
    > div {
      margin: 0 8px 8px 0;
      font-style: normal !important;

      > label {
        display: block;
      }
      > span {
        font-style: normal !important;
      }
      .vue-quick-edit {
        display: inline-block;
        margin-left: 8px;
        font-style: normal !important;
      }
    }
  }
}
</style>
