<template>
  <PageContentLayoutOnly role="region" :aria-label="$t('profile.title')">
    <div class="notification headerColor box is-4">
      <div>
        <ChangePassword scope="management" />
      </div>
      <div v-show="false">
        <fieldset>
          <div class="column is-12" style="padding:10px; white-space:nowrap;">
            <label class="label">Additonal Authentication</label>
          </div>
        </fieldset>
        <b-button type="button" class="is-medium expanded" expanded :disabled="loading">
          Set Up
        </b-button>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { mapState, mapGetters } from 'vuex'
import ChangePassword from '@/components/ChangePasswordModal'

import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    PageContentLayoutOnly,
    ChangePassword
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  mounted() {
    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: { profile: { title: 'Profile' } }
    }
  }
}
</script>

<style lang="scss">
textarea {
  min-width: 600px !important;
}

button {
  padding: 5px;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 940px;
  }

  .app-properties {
    > div {
      margin: 0 8px 8px 0;
      font-style: normal !important;

      > label {
        display: block;
      }
      > span {
        font-style: normal !important;
      }
      .vue-quick-edit {
        display: inline-block;
        margin-left: 8px;
        font-style: normal !important;
      }
    }
  }
}
</style>
