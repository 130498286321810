import { ValidationError } from '@/services/errors'
import { mapActions } from 'vuex'
import kms from '@/services/kms'
import { profileImageStore } from '@/services/UserProfileImage/store'
import { notifyMessage, notifyError } from '@/services/notify'

let DEFAULT_FORM_VALUES = {
  fullName: '',
  emailAddress: '',
  phone: ''
}

export const methods = {
  ...mapActions('user', ['selectHoaId']),

  confirmUpdate() {
    this.$buefy.dialog.confirm({
      title: 'Updating Profile',
      message:
        'Are you sure you want to <b>update</b> this record? Changing your email address will require that you use your new email address the next time that you login.',
      confirmText: 'Update User Profile',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.onProfileFormSubmit()
    })
  },

  saveProfileImage() {
    console.debug('saveProfileImage...')
    this.toggle = true
  },

  removeProfileImage() {
    console.debug('removeProfileImage...')
  },

  async reload() {
    this.loading = true

    this.refreshUserProfile()

    this.formData = { ...DEFAULT_FORM_VALUES }
    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    this.loading = false
  },

  async refreshUserProfile() {
    try {
      const refreshedUser = await kms.get('/User')

      if (this.isDebug == true) console.debug('refreshedUser=' + JSON.stringify(refreshedUser))

      if (refreshedUser && refreshedUser != undefined) {
        this.profile = refreshedUser
        this.formData.fullName = this.profile.name
        this.formData.emailAddress = this.profile.email
        this.formData.phone = this.profile.phoneNumber

        await this.loadCurrentDocument()
        if (this.isDebug == true) console.debug('this.profile=' + JSON.stringify(this.profile))
      } else {
        if (this.isDebug == true) console.debug('else this.profile')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  async loadCurrentDocument() {
    console.debug('in loadCurrrentDocument()...')
    if (
      !this.profile ||
      !this.profile.profileImageDocumentID ||
      this.profile.profileImageDocumentID <= 0
    ) {
      return
    }

    console.debug('in loadCurrrentDocument()...')

    try {
      const params = {
        asBase64: true
      }

      await profileImageStore.dispatch('getProfileImageFile', {
        params: params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true)
              console.debug(
                ' - params' + JSON.stringify(params) + ' - this.getProfileImageFile=' + details
              )

            this.base64pdf = null
            this.imageContent = details
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
    }
  },

  getProfileFormPayload() {
    const payload = {
      name: this.formData.fullName,
      email: this.formData.emailAddress,
      phoneNumber: this.formData.phone
    }

    return payload
  },

  async onProfileFormSubmit() {
    try {
      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem saving your user profile.')
        return
      }

      this.loading = true
      console.debug('payload formData= ' + JSON.stringify(this.formData))
      const returnPayload = this.getProfileFormPayload()
      console.debug('payload= ' + JSON.stringify(returnPayload))

      try {
        const path = `/User/SaveIdentityUserProfile`
        const results = await kms.post(path, returnPayload)

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results && results != undefined) {
          if (results == true) {
            notifyMessage(`Your user profile has been updated`)
            this.reload()
          } else {
            notifyError(`There was a problem updating your user profile`)
          }
        } else {
          notifyError('There was a problem updating the detailed description.')
        }
        this.loading = false
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    } catch (e) {
      //If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      } else {
        notifyError(e)
      }
    }

    this.loading = false
  }
}
