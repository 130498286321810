export const methods = {
  async reload() {
    this.loading = true

    if (this.$route.query.fromFirstLogin) {
      if (this.isDebug === true) console.debug('query param=' + this.$route.query.fromFirstLogin)

      this.fromFirstLogin = true
    } else {
      this.fromFirstLogin = false
    }

    this.loading = false
  }
}
