export const data = function() {
  return {
    isDebug: true,
    loading: false,
    fromFirstLogin: false,
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    }
  }
}
